<template>
    <div class="samoo-demo">
      <VueSlickCarousel
          v-bind="settings"
          ref="roomCarousel"
          @beforeChange="onChangeCarousel"
          :key="carouselKey"
          controls 
          indicators
      >
          <div v-for="(item, index) in vr360List" :key="index" class="item">
            <template>
              <div class="item">                
                  <div class="custom-img">
                    <div
                      v-html="getVR360(item.vr360Link)"
                      class="vr360"
                      v-if="item.id === currentWatchRoom.id && !vr360Loading"
                    ></div>
                    <div v-if="vr360Loading" class="vr360-loading">
                      <div class="loading-icon">
                        <div
                          class="spinner-border"
                          style="width: 6rem; height: 6rem"
                          role="status"
                        >
                          <span class="visually-hidden"> </span>
                        </div>
                        <img
                          src="https://lifeon-v2.sgp1.cdn.digitaloceanspaces.com/admin/100xAUTO/2022_8_19_14_42_31_437_[%EB%9D%BC%EC%9D%B4%ED%94%84%EC%98%A8]%EC%95%B1%EC%95%84%EC%9D%B4%EC%BD%98.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                      
                  <!-- <div class="slide-bottom row">
                    <div class="col-6 text-center" @click="$refs.roomCarousel.prev()">
                      <p v-if="index === 0">
                        {{ vr360List[vr360List.length - 1]?.room_name }}
                      </p>
                      <p v-if="index > 0">{{ vr360List[index - 1]?.room_name }}</p>
                      <b-icon 
                        icon="arrow-left"ß
                        aria-hidden="true"
                        scale="3"
                      ></b-icon>
                    </div>
                    <div class="col-6 text-center" @click="$refs.roomCarousel.next()">
                      <p v-if="index < vr360List.length - 1">
                        {{ vr360List[index + 1]?.room_name }}
                      </p>
                      <p v-if="index === vr360List.length - 1">
                        {{ vr360List[0]?.room_name }}
                      </p>
                      <b-icon
                        icon="arrow-right"
                        aria-hidden="true"
                        scale="3"
                      ></b-icon>
                    </div>
                  </div> -->
              </div>
            </template>
          </div>
        </VueSlickCarousel>
    </div>  
</template>
<style lang="scss" scoped>
  @import "~sass-rem";
  @import "@/assets/scss/samoo.scss";
</style>
  
<script>
    import VueSlickCarousel from "vue-slick-carousel";
    import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
    export default {
        name: "SamooDemo",
        components: {
          VueSlickCarousel,
        },
        data() {
          return {
              vr360List: [] ,
              currentWatchRoom: {},
              // settings: {
              //     arrows: true,
              //     dots: false,
              //     infinite: true,
              //     initialSlide: 0,
              //     speed: 1000,
              //     slidesToShow: 1,
              //     slidesToScroll: 1,
              //     swipeToSlide: true,
              //     autoplay: false,
              // },
              setting: {
                "dots": true,
                "dotsClass": "slick-dots custom-dot-class",
                "edgeFriction": 0.35,
                "infinite": false,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1
              },
              vr360Loading: false,
              carouselKey: 0,
          }
        },
        async created() {
          this.vr360List = [
                  {
                      id: 1,
                      name: 'SM Brainstorm room',
                      vr360Link : 'https://kuula.co/share/collection/7ZPB8?logo=1&info=0&logosize=138&fs=0&vr=1&sd=1&gyro=0&autorotate=1.5&autop=5&thumbs=-1'
                  },
                  {
                      id: 2,
                      name: 'SM Meeting room',
                      vr360Link : 'https://kuula.co/share/collection/7ZPBT?logo=1&info=0&logosize=73&fs=0&vr=1&sd=1&autorotate=1.5&thumbs=1&inst=0'
                  },
                  {
                      id: 3,
                      name: 'SM Director room',
                      vr360Link : 'https://kuula.co/share/collection/7ZPBM?logo=1&info=0&logosize=73&fs=0&vr=1&sd=1&autorotate=1.5&thumbs=1&inst=0'
                  }
          ]
        },
        computed: {},
        methods: {
            // getVRHtml() {
            //     return `vrOption${this.option}`
            // },
            getVR360(link = "") {
                return `<iframe width="100%" height="890px" style="border: none; max-width: 100%;" frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="${link}"></iframe>`;
            },
            onChangeCarousel(oldIndex, newIndex) {
              this.currentWatchRoom = this.vr360List?.[newIndex];
              this.vr360Loading = true;
              setTimeout(() => {
                this.vr360Loading = false;
              }, 1000);
            },
        },
        mounted() {},
        watch: {
          vr360List(vr360Arr) {
            console.debug('Vr360Block.vue > watch > vr360List > vr360Arr ', vr360Arr);
            if (vr360Arr?.length > 0) {
              this.currentWatchRoom = vr360Arr[0];
              this.carouselKey += 1;
            }
          },
        },
    };
</script>
  