<template>
  <div class="my-demo">
    <!-- <div>
      <b-nav pills>
        <b-nav-item :active="option === 'A'" @click="option = 'A'">Option A</b-nav-item>
        <b-nav-item :active="option === 'B'" @click="option = 'B'">Option B</b-nav-item>
      </b-nav>
    </div>
    <div v-if="option === 'A'">
        <div v-html="vrOptionA" id="vr360" style="height: 96vh;"></div>
    </div>
    <div v-if="option === 'B'">
        <div v-html="vrOptionB" id="vr360" style="height: 96vh;"></div>
    </div> -->
    <!-- <div v-if="option === 'A'">
      <div v-html="vrOptionA" id="vr360" style="height: 96vh;"></div>
    </div>
    <div v-if="option === 'B'">
      <div v-html="vrOptionD" id="vr360" style="height: 96vh;"></div>
    </div> -->
    <div v-if="option === 'ph-1'">
      <div v-html="vrOptionPH_1" id="vr360" style="height: 96vh;"></div>
    </div>
    <div v-if="option === 'ph-2'">
      <div v-html="vrOptionPH_2" id="vr360" style="height: 96vh;"></div>
    </div>
    <div v-if="option === 'gal-c305'">
      <div v-html="vrOptionGalleria_C305" id="vr360" style="height: 96vh;"></div>
    </div>
    <div v-if="option === 'rm-ch06'">
      <div v-html="vrOptionRiseMount_CH06" id="vr360" style="height: 96vh;"></div>
    </div> 
    <div v-if="option === 'samoo-vr360'">
      <div v-html="vr360Samoo_Demo" id="vr360" style="height: 96vh;"></div>
    </div>   
  </div>
</template>
<style lang="scss" scoped>
@import "~sass-rem";
@import "@/assets/scss/main.scss";
</style>

<script>
// import vr360s from "./package-data.js"
export default {
  name: "Demo",
  components: {},
  data() {
    // update vrOptionGalleria_C305
    return {
        option: this.$route.params.vr_id,
        vrOptionA: `<iframe width="100%" height="100%" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowfullscreen scrolling="no" src="https://kuula.co/share/collection/7cbK8?logo=1&info=0&fs=1&vr=1&sd=1&autorotate=1.36&autop=10&thumbs=-1&keys=0"></iframe>`,
        vrOptionB: `<iframe width="100%" height="100%" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowfullscreen scrolling="no" src="https://kuula.co/share/collection/7cbZ7?logo=1&info=0&fs=1&vr=1&sd=1&autorotate=1.36&autop=10&thumbs=-1&keys=0"></iframe>`,
        vrOptionC: `<iframe width="100%" height="100%" style="width: 100%; height: 100%; border: none; max-width: 100%;" frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7KFwS?logo=1&info=0&fs=1&vr=1&sd=1&autorotate=1.36&autop=10&thumbs=-1&keys=0"></iframe>`,
        vrOptionD: `<iframe width="100%" height="100%" style="width: 100%; height: 100%; border: none; max-width: 100%;" frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7KFwS?logo=1&info=0&fs=1&vr=1&sd=1&autorotate=1.36&autop=10&thumbs=-1&keys=0"></iframe>`,
        "vrOptionPH_1": `<iframe width="100%" height="100%" style="width: 100%; height: 100%; border: none; max-width: 100%;" frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7Kxjq?logo=1&info=0&fs=0&vr=0&sd=1&gyro=0&autorotate=1.5&autop=20&thumbs=-1&alpha=0.82&keys=0"></iframe>`,
        "vrOptionPH_2": `<iframe width="100%" height="100%" style="width: 100%; height: 100%; border: none; max-width: 100%;" frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7KC4B?logo=1&info=0&fs=0&vr=0&sd=1&gyro=0&autorotate=1.5&autop=20&thumbs=-1&alpha=0.82&keys=0"></iframe>`,
        "vrOptionGalleria_C305": `<iframe width="100%" height="100%" style="width: 100%; height: 100%; border: none; max-width: 100%;" frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7KRHC?logo=1&info=0&fs=0&vr=0&sd=1&gyro=0&autorotate=1.5&autop=17&thumbs=-1&alpha=0.82&keys=0"></iframe>`,
        "vrOptionRiseMount_CH06": `<iframe width="100%" height="100%" style="width: 100%; height: 100%; border: none; max-width: 100%;" frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7Kfq2?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=1.5&autop=17&thumbs=-1&alpha=0.82&keys=0"></iframe>`,
        "vr360Samoo_Demo" : `<iframe src="https://3dwarehouse.sketchup.com/embed/015f4740-e8a3-49fc-81ac-433f4bbf168c?token=tzSRl82P-f4=&binaryName=s21" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="100%" height="100%" allowfullscreen></iframe>`
      };
  },
  async created() {
    //   this.getVr360List();
  },
  computed: {},
  methods: {
    getVRHtml() {
      return `vrOption${this.option}`
    }
  },
  mounted() {},
  watch: {
    "$store.state.lang"(val) {},
  },
};
</script>
